import React, { useState, useEffect } from "react";
import "../css/layout.css";
import "../css/background-image.css";
import Helmet from "react-helmet";
import axios from "axios";
import Spinner from "@atlaskit/spinner";
import { ZoomMtg } from "@zoomus/websdk";
import Textfield from "@atlaskit/textfield";
import { format, parseISO } from "date-fns";
import Button from "@atlaskit/button";
import backBottom from "../images/logo.png";
import banner from "../images/newbanner.jpg";
import detailImg from "../images/detail.jpg";
import joinaudio from "../images/joinlowd.jpg";

const isBrowser = typeof window !== "undefined";

export default ({ props }) => {
  const oldZoom = [
    {
      roomId: "86755303900",
      passcode: "1111",
      id: "1",
      url: "https://us06web.zoom.us/j/86755303900?pwd=Y2Z2d2dNOWJFOGN2RVVTMXVVNXU3Zz09",
      base_url:
        "https://us06web.zoom.us/j/86755303900?pwd=Y2Z2d2dNOWJFOGN2RVVTMXVVNXU3Zz09&openExternalBrowser=1",
    },
  ];

  useEffect(async () => {
    import("@zoomus/websdk").then((module) => {
      const { ZoomMtg } = module;

      ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.6/lib", "/av");
      console.log("checkSystemRequirements");
      console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareJssdk();
      ZoomMtg.i18n.load("en-US");
      ZoomMtg.i18n.reload("en-US");
    });

    // const liff = window.liff;
    // try {
    //   await liff.init({ liffId: "1656224695-AoDYdOkq" });
    //   if (!liff.isLoggedIn()) {
    //     liff.login();
    //   } else {
    //     try {
    //       let response = await liff.getProfile();
    //       if (Object.keys(response).length != 0) {
    //         setuserData(response);
    //       } else {
    //         let response = await liff.getProfile();
    //         setuserData(response);
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  }, []);

  // data name
  let randomNumber = 0;
  function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }
  randomNumber =
    "ธรรมล้านดวงKIDS " + getRandomIntInclusive(100000, 999999).toString();

  const [value, setValue] = useState(randomNumber);
  const [currentDate, setCurrentDate] = useState("");
  const handleChange = (e) => setValue(e.target.value);

  //data request
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUrl, setIsLoadingUrl] = useState(false);
  const [dataRoom, setDataRoom] = useState({});
  const [urlData, setUrlData] = useState({});
  const [userData, setuserData] = useState({});

  function startMeeting(signature, meetingNumber, passWord) {
    if (isBrowser) {
      document.getElementById("zmmtg-root").style.display = "block";
      ZoomMtg.init({
        leaveUrl: "https://web.facebook.com/dhamma1000000",
        isSupportAV: true,
        disablePreview: true, // default false
        success: (success) => {
          console.log(success);
          ZoomMtg.join({
            signature: signature,
            meetingNumber: meetingNumber,
            userName: value,
            apiKey: "Wr1maFh7QnOOCvAOhiCKTw",
            userEmail: "",
            passWord: passWord,
            success: (success) => {
              console.log("join meeting success");
              console.log(success);

              const buttonLeave =
                document.getElementsByClassName("zmu-btn--danger");
              if (buttonLeave.length !== 0) {
                buttonLeave[0].addEventListener("click", () => {
                  ZoomMtg.leaveMeeting({});
                });
              }
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  const requestTargetRoom = async () => {
    setIsLoading(true);

    let dateData = new Date();
    // let query_date = format(dateData, "dd-MM-yyyy");
    // // change api
    // console.log(query_date);
    // const checkDate = await axios({
    //   method: "get",
    //   url: "https://api.thedhamma.net/socialcollect/line/date",
    //   params: {
    //     date: query_date,
    //   },
    // });

    const postNewRecord = await axios.put(
      "https://9dxnbtvny5.execute-api.ap-southeast-1.amazonaws.com/test/items",
      {
        id: value,
        userName: value,
        typeUrl: "web",
        lineOA: "direct",
        dateData: format(dateData, "dd-MM-yyyy"),
        timeData: format(dateData, "HH:mm"),
      }
    );

    //console.log(checkDate);
    // if (Object.keys(checkDate.data).length == 0) {
    //   const postNewRecord = await axios({
    //     method: "post",
    //     headers: { "Content-Type": "application/json" },
    //     url: "https://api.thedhamma.net/socialcollect/line/user",
    //     data: {
    //       user: {
    //         userId: userData.userId,
    //         userName: userData.displayName,
    //         userProfile: userData.pictureUrl,
    //         typeUrl: "web",
    //         lineOA: "allmonk",
    //         dateData: format(dateData, "dd-MM-yyyy"),
    //         timeData: format(dateData, "HH:mm"),
    //       },
    //       dateTimeData: format(dateData, "dd-MM-yyyy"),
    //     },
    //   });
    // } else {
    //   const postNewRecord = await axios({
    //     method: "put",
    //     headers: { "Content-Type": "application/json" },
    //     url: "https://api.thedhamma.net/socialcollect/line/user",
    //     data: {
    //       user: {
    //         userId: userData.userId,
    //         userName: userData.displayName,
    //         userProfile: userData.pictureUrl,
    //         typeUrl: "web",
    //         lineOA: "allmonk",
    //         dateData: format(dateData, "dd-MM-yyyy"),
    //         timeData: format(dateData, "HH:mm"),
    //       },
    //       dateTimeData: format(dateData, "dd-MM-yyyy"),
    //     },
    //   });
    // }
    // let target = 1;
    // const result = await axios({
    //   method: "get",
    //   url: "https://gshoim1tph.execute-api.ap-southeast-1.amazonaws.com/test/api",
    //   // url: "https://api.thedhamma.net/redirect/room",
    //   // url: "https://random-room-vov26botrq-as.a.run.app/range",
    // });
    //////////////////////////////////////////
    // if (result.status === 200) {
    //   console.log(result.data);
    //   let randArr = [];
    //   let initStart = result.data.start - 1;
    //   if (result.data.mode == 1) {
    //     for (let i = initStart; i < result.data.end; i++) {
    //       randArr.push(oldZoom[i]);
    //     }
    //     let randomRoom = randArr[Math.floor(Math.random() * randArr.length)];
    //     console.log(randomRoom);
    //     target = randomRoom;
    //   } else if (result.data.mode == 2) {
    //     for (let i = initStart; i < result.data.end; i++) {
    //       randArr.push(newZoom[i]);
    //     }
    //     let randomRoom = randArr[Math.floor(Math.random() * randArr.length)];
    //     console.log(randomRoom);
    //     target = randomRoom;
    //   }

    //   const getSignatures = await axios({
    //     method: "post",
    //     headers: { "Content-Type": "application/json" },
    //     // url: "https://api.thedhamma.net/genSignature",
    //     url: "https://gensig-t5wbthbpjq-as.a.run.app",
    //     data: {
    //       // meetingNumber: target.roomID,
    //       meetingNumber: target.roomId,
    //       role: 0,
    //     },
    //   });
    //   startMeeting(
    //     getSignatures.data.signature,
    //     target.roomId,
    //     target.passcode
    //   );
    // }

    //console.log(result);

    // if (result.status === 200) {
    // let roomNumber = result.data.roomNumber;
    // console.log(roomNumber);
    // let roomID = newZoom[parseInt(roomNumber) - 1].roomId;
    // console.log(roomID);
    const getSignatures = await axios({
      method: "post",
      headers: { "Content-Type": "application/json" },

      // url: "https://api.thedhamma.net/genSignature",

      url: "https://gensig-t5wbthbpjq-as.a.run.app",
      data: {
        meetingNumber: oldZoom[0].roomId,
        role: 0,
      },
    });
    //console.log(getSignatures.data.signature);
    //console.log(getSignatures.data);

    startMeeting(
      // getSignatures.data,

      getSignatures.data.signature,
      oldZoom[0].roomId,
      1111
    );
    // }

    setIsLoading(false);
  };

  const urlTargetRoom = async () => {
    setIsLoadingUrl(true);
    // const result1 = await axios({
    //   method: "get",
    //   url: "https://gshoim1tph.execute-api.ap-southeast-1.amazonaws.com/test/api",
    //   // url: "https://random-room-vov26botrq-as.a.run.app/range",
    // });
    /////////////////////////////////////////////
    // if (result1.status === 200) {
    //   console.log(result1.data);
    //   let roomNumber = result1.data.roomNumber;
    //   let roomlink = newZoom[parseInt(roomNumber) - 1].base_url;
    //   // let randArr = [];
    //   // let initStart = result1.data.start - 1;
    //   // if (result1.data.mode == 1) {
    //   //   for (let i = initStart; i < result1.data.end; i++) {
    //   //     randArr.push(oldZoom[i]);
    //   //   }
    //   //   let randomRoom = randArr[Math.floor(Math.random() * randArr.length)];
    //   //   //console.log(randomRoom);
    //   //   window.location.href = randomRoom.base_url;
    //   // } else if (result1.data.mode == 2) {
    //   //   for (let i = initStart; i < result1.data.end; i++) {
    //   //     randArr.push(newZoom[i]);
    //   //   }
    //   //   let randomRoom = randArr[Math.floor(Math.random() * randArr.length)];
    //   //   //console.log(randomRoom);
    //   //window.location.href = randomRoom.base_url;
    //   window.location.href = roomlink;
    //   //}
    // } else {
    //   // const room = {
    //   //   roomID: "94881671898",
    //   //   passcode: "1111",
    //   //   id: "1",
    //   //   url: "https://zoom.us/j/94881671898?pwd=VjJ3RjVDY3VnUGpkSGVUQWQzR1RGZz09",
    //   //   base_url:
    //   //     "https://zoom.us/j/94881671898?pwd=VjJ3RjVDY3VnUGpkSGVUQWQzR1RGZz09&openExternalBrowser=1",
    //   // };
    //   window.location.href = newZoom[22 - 1].base_url;
    // }

    // const result = await axios({
    //   method: "get",
    //   // url: "https://api.thedhamma.net/redirect/room/new",
    //   url: "https://api.thedhamma.net/redirect/room",
    // });

    //console.log(result);
    // if (result.status == 200) {
    //   setUrlData(result.data);

    //   window.location.href = result.data.base_url;
    // }
    window.location.href = oldZoom[0].base_url;
    setIsLoadingUrl(false);
  };

  return (
    <>
      <Helmet>
        <title>Zoom on web by ธรรมล้านดวง KIDS</title>

        <meta name="format-detection" content="telephone=yes"></meta>
        <meta name="title" content="Zoom on Web by ธรรมล้านดวง KIDS"></meta>
        <meta name="description" content="ธรรมล้านดวง KIDS"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content=""></meta>
        <meta
          property="og:title"
          content="Zoom on Web by ธรรมล้านดวง KIDS"
        ></meta>
        <meta property="og:description" content="ธรรมล้านดวง KIDS"></meta>
        <meta property="og:image" content="g"></meta>
        <link
          type="text/css"
          rel="stylesheet"
          href="https://source.zoom.us/1.9.6/css/bootstrap.css"
        />
        <link
          type="text/css"
          rel="stylesheet"
          href="https://source.zoom.us/1.9.6/css/react-select.css"
        />
      </Helmet>

      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        {/* Top */}
        <div
          className="colorbg"
          style={{
            height: "30%",
            width: "100%",
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            backgroundImage: `url(${banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        >
          <h1
            style={{
              color: "#5c2605",
              textAlign: "center",
              fontFamily: "duangkaewregular",
            }}
          ></h1>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            width: "100%",
            height: "10%",
            backgroundImage: `url(${detailImg})`,
            backgroundSize: "cover",
          }}
        ></div>
        <div
          className=""
          style={{
            height: "30%",
            width: "100%",
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            backgroundImage: `url(${joinaudio})`,
            backgroundSize: "cover",
          }}
        ></div>

        {/* Bottom */}
        <div
          className="bgimgBottom"
          style={{
            backgroundColor: "#ffffff",
            width: "100%",
            height: "40%",
            position: "relative",
            justifyContent: "center",
            bottom: 0,
            backgroundImage: `url(${backBottom})`,
            backgroundSize: "contain",
          }}
        >
          {/* part1 */}
          {/* <div style={{ width: "100%", backgroundColor: "#d79f00" }}>
            <div
              style={{
                width: "100%",
                height: 50,
                backgroundColor: "#ffffff",
                borderRadius: "30px 30px 0 0",
                boxShadow: "0 -7px 7px -5px #5B5B5B",
              }}
            ></div>
          </div> */}

          {/* part2 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <h4
              style={{
                color: "#96938c",
                marginTop: "40px",
                width: "160px",
                marginBottom: "30px",
              }}
            >
              กรอกชื่อเข้าร่วมงาน
            </h4>

            <Textfield
              placeholder="กรอกชื่อของคุณ
      "
              name="basic"
              defaultValue={value}
              onChange={handleChange}
              aria-label="default text field"
              css={{ width: "60%", marginBottom: "30px", textAlign: "center" }}
            />
            <br />
            <br />

            <Button
              appearance="primary"
              style={{
                backgroundColor: "#d79f00",
                borderRadius: "20px 20px 20px 20px",
                width: "200px",
                boxShadow: "2px 5px 16px 0px #A3A3A3",
                marginBottom: "30px",
              }}
              onClick={requestTargetRoom}
            >
              {isLoading && <Spinner />}
              &nbsp;&nbsp; กดตรงนี้เข้าร่วมงาน
            </Button>

            <button onClick={urlTargetRoom} style={{ color: "#dea10a" }}>
              {isLoadingUrl && <Spinner />}
              &nbsp;&nbsp;ร่วมกิจกรรมผ่าน Application Zoom
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
